.new-reports-container {
    padding: 20px 0;
}
.reports-container > a.report {
    min-width: 100px;
    min-height: 300px;
}
.published-carousel .react-multiple-carousel__arrow {
    z-index: 100;
}
.report-card {
    width: 200px;
    height: 260px;
}
.reports-page-container .reports-container .year-selector {
    display: flex;
    justify-content: flex-end;
    padding: 10px 0;
}
.parent-tooltip {
    position: relative;
    width: fit-content;
    margin-left: 15px;
}
.child-tooltip {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
.create-new-report-modal div.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}
.report-modal {
    max-width: 500px;
}
.year-selector div.ui.selection.dropdown {
    width: 100px !important;
    z-index: 101;
}
