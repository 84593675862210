header {
    width: 100%;
    background-color: #eceff2;
    padding: 0 50px;
}
header > div.menu .item {
    padding: 25px 20px !important;
    font-size: 16px;
    font-weight: bold !important;
    color: #1c3968 !important;
    transition: all 0.15s ease-in-out !important;
    cursor: pointer;
    margin: 0 !important;
}
header .menu .item:hover,
header .menu .active.item {
    color: #666868 !important;
    background-color: transparent !important;
}
header .dropdown .menu.transition {
    border-radius: 0 !important;
    margin-top: -2px;
    margin-left: -10px;
}
header .dropdown .menu.transition .item {
    min-height: 35px !important;
}
header .menu .ui.active.visible.item.dropdown .menu.transition.visible {
    min-width: fit-content;
    padding-bottom: 6px;
}
header > div.menu .item > i {
    margin: 0 10px !important;
}
header > div.menu .header.item {
    padding: 0 !important;
}
header > div.menu .header.item img {
    width: 200px !important;
}
header > div.menu .item .input > i {
    border-left: 2px solid #bcbec0;
    opacity: 1 !important;
    color: #5f6774 !important;
}
header > div.menu .right.item {
    padding: 0 !important;
    margin-right: 20px !important;
}

header div.divider.text {
    color: rgb(28, 77, 104) !important;
}
#root div#logo-container {
    display: flex;
    flex-direction: column;
    align-items: center;
}
#root p#imp-logo-text {
    max-width: 160px;
    text-align: center;
    color: #1c3968 !important;
    font-size: 10px;
    margin-top: -12px;
    letter-spacing: 3px;
}

.region-select-modal .header {
    text-align: center;
}

.region-select-modal .actions {
    display: flex;
    justify-content: space-around;
}
