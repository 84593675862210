@font-face {
    font-family: "Roboto";
    src: url("./fonts/Roboto.ttf");
    font-weight: normal;
    font-style: normal;
}
html,
body {
    margin: 0 !important;
    background-color: #e0e4e7;
}

body > #root {
    min-height: 100vh;
    overflow-x: hidden;
    overflow-y: auto;
}

body > #root > #layout,
body > #root > #layout div,
body > #root .input input,
body > #root .button,
body > #root > #layout a,
body > #root label,
body > #root .divider,
body > #root .selection .item,
body > .ui.modals .header,
body > .ui.modals label,
body > .ui.modals input,
body > .ui.modals .button,
body > .ui.modals .divider,
body > .ui.modals .selection .item {
    font-family: "Roboto", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
#layout {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}
#layout header,
#layout footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100px;
}
#layout main,
#layout footer > div {
    width: 90%;
}
#layout header .menu {
    width: 100%;
}
#layout header .menu .item,
#layout header .menu .right.item,
#layout footer div {
    padding: auto 15px !important;
    max-height: 20px;
}
#layout main {
    align-self: center;
    margin-top: 30px;
    margin-bottom: 30px;
}
#layout footer {
    margin-top: auto;
}
h1,
h2,
h3,
h4,
h5,
h6 {
    color: #1c3968;
    font-family: "Roboto", Lato, "Helvetica Neue", Arial, Helvetica, sans-serif !important;
}
h1 {
    font-size: 36px;
}
#root p,
#root div,
#root label,
#root input,
#root .ui.table td,
#root .ui.selection,
#root .ui.selection .visible.menu,
.ui.dimmer > .ui.modal > .content input,
.ui.dimmer > .ui.modal > .content .ui.selection,
.ui.dimmer > .ui.modal > .content .ui.selection .visible.menu .item,
.ui.dimmer > .ui.modal > .content h4,
body > .ui.modals * {
    color: #666868;
}

#root div.ui.selection > div.visible.menu.transition,
.ui.dimmer > .ui.modal > .content div.ui.selection > div.visible.menu.transition {
    margin: 0;
    min-width: 100% !important;
    width: calc(100% + 2px) !important;
    left: -1px;
    box-shadow: none;
}
#root div.ui.selection > div.visible.menu.transition > div,
.ui.dimmer > .ui.modal > .content div.ui.selection > div.visible.menu.transition > div {
    border: none;
}
#root div.ui.selection > input,
.ui.dimmer > .ui.modal > .content div.ui.selection > input {
    left: 0 !important;
    right: 0 !important;
}

#root .ui.segment {
    border-radius: 15px;
    margin: 0;
    padding: 25px 50px !important;
}
#root .ui.segment.grey-segment,
#root .ui.message {
    border: none;
    box-shadow: none;
    background: #f0f1f2;
}
#root .ui.button,
.ui.dimmer > .ui.modal > .actions button {
    margin: 0;
}
#root .ui.button:hover,
.ui.dimmer > .ui.modal > .actions > .ui.button:hover {
    transition: all 0.1s ease-in-out;
    opacity: 0.8;
}
#root .ui.primary.button,
#root .ui.toggle.checkbox > input:checked ~ label:before,
.ui.dimmer > .ui.modal > .actions .ui.primary.button {
    background-color: #1c3968;
}
#root .ui.secondary.button {
    background-color: #666868;
}
#root .ui.negative.button,
.ui.dimmer > .ui.modal > .actions > .ui.negative.button {
    background-color: #c0392b;
}
.ui.dimmer > .ui.modal > .actions button:not(:first-of-type) {
    margin-left: 15px;
}

.section-row {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    gap: 20px;
}
.section-row label,
.input-container label {
    flex: 1;
    font-weight: bold;
}
.section-row .input {
    flex: 1;
}
.input-container {
    display: flex;
    flex-direction: column;
    gap: 10px;
}
#root h1 {
    font-size: 2.5rem;
    margin-bottom: 20px;
}
.fade-enter {
    opacity: 0;
    transform: scale(0.9);
}
.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 300ms, transform 300ms;
}
.fade-exit {
    opacity: 1;
}
.fade-exit-active {
    opacity: 0;
    transform: scale(0.9);
    transition: opacity 300ms, transform 300ms;
}
.table-pagination {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center;
    line-height: 1;
    gap: 20px;
    margin-left: auto;
}
#root i.disabled {
    pointer-events: none;
}
body > div.ui.basic.segment.toast-container.visible.transition {
    width: 100%;
    position: fixed;
    top: 10px;
    z-index: 5000;
    display: flex !important;
    flex-direction: row;
    justify-content: center;
    margin: 0 !important;
    padding: 0 !important;
}
.toast-message {
    border-color: transparent !important;
    box-shadow: none !important;
    font-weight: bold;
    color: #f1f2f2 !important;
    padding: 7px 14px !important;
    text-shadow: 1px 1px 2px #414042;
}
.positive.toast-message {
    background-color: #43a047 !important;
}
#root i.green {
    color: #43a047 !important;
}
.negative.toast-message {
    background-color: #c0392b !important;
}
#root .cell-bolded {
    font-weight: bold;
}
.container-header {
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    border-bottom: 2px solid #666868;
}
.container-header .ui.selection > .text {
    font-weight: bold;
}

.ui.dimmer > .ui.modal {
    border: none;
    box-shadow: rgba(99, 99, 99, 0.4) 0px 0px 25px 5px;
    background: #f0f1f2;
    border-radius: 15px;
    margin: 0;
}
.ui.dimmer > .ui.modal > div {
    background: #f0f1f2;
    color: #5f6774;
    padding-left: 25px;
    padding-right: 25px;
}
.ui.dimmer > .ui.modal > div:first-of-type {
    border-radius: 15px 15px 0 0;
}
.ui.dimmer > .ui.modal > div:last-of-type {
    border-radius: 0 0 15px 15px;
}
textarea {
    border-style: none;
    border-radius: 10px;
    padding: 10px;
    resize: none;
}
textarea:focus-visible {
    outline: none;
}
#topAddCertificate {
    margin-bottom: 10px;
}
#bottomAddCertificate {
    margin-top: 10px;
}
#root button i {
    font-size: 12px;
}
.input-required {
    margin-left: 2px;
    color: #c0392b !important;
}
#root .react-multi-carousel-list {
    margin: 10px 0;
    padding: 10px 0;
}
#root .react-multi-carousel-list .react-multi-carousel-item {
    padding: 0 10px;
}

.action-bar-container-options i {
    background-color: white;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.25) !important;
    border: 1px solid;
    box-shadow: none !important;
    margin-bottom: 2px;
    padding-left: 6px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 500ms linear, background-color 500ms linear, color 500ms linear;
}

.action-bar-container:hover .action-bar-container-options i,
.action-bar-container-options:hover i {
    opacity: 1;
}

.action-bar-container-options i:hover {
    background-color: #1c3968;
    color: white;
    border-color: transparent !important;
}

.action-bar-container {
    display: flex !important;
}

.action-bar-container-options {
    margin-left: 6px;
}

.clickable {
    cursor: pointer !important;
}
