.form-editor-container .section-container {
    margin: auto;
    padding-bottom: 64px;
    width: 820px;
    margin-bottom: 20px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.form-editor-container .section-container > .ui.cards {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.form-editor-container .section-container .card {
    margin-left: 0 !important;
    margin-right: 0 !important;
}

.form-editor-container .editor {
    display: flex;
    align-items: flex-start;
}

.form-editor-container .add-question {
    margin-top: 15px !important;
}

.form-editor-container .form-editor-actions {
    display: flex;
    flex-direction: row;
}

.form-editor-container .form-editor-actions button {
    margin-left: 15px !important;
}

.form-editor-container .form-editor-actions a {
    margin-left: 15px !important;
}

.form-editor-container .container-header h1 {
    max-width: 60%;
}

.dismiss-container {
    display: flex;
    align-items: center;
    margin-top: 15px;
}

.dismiss-container label {
    color: #5f6774 !important;
    padding-left: 10px;
}

span.action-span {
    margin-left: 15px;
}
