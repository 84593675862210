div.CookieConsent {
    background-color: #F9F7F7 !important;
    z-index: 1000 !important;
}

button#rcc-decline-button {
    background-color: #2A315A !important;
}

button#rcc-confirm-button {
    background-color: #FFCC00 !important;
}