.legend-horizontal-container{
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin: 15px 0;
}

.legend-horizontal-container .legend-item {
    display: flex;
    align-items: center;
    margin: 10px 0;
    font-size: medium;
}

.legend-item .ui.yellow.circular.label {
    background-color: rgb(255, 213, 0)!important;
}

.legend-item .ui.blue.circular.label {
    background-color: rgb(13, 77, 161)!important;
}

.legend-item .ui.green.circular.label {
    background-color: rgb(0,169,144)!important;
}

.legend-item .ui.orange.circular.label {
    background-color: rgb(255,106,0)!important;
}