.table-input-create .table-dimension-input {
    display: flex;
    margin: 4px -5px;
}

.table-input-create .table-dimension-input > * {
    margin: 0 5px !important;
}

.table-input-create .table-dimension-input button {
    min-width: 150px;
}

.table-input-create .table-wrapper {
    overflow: auto;
}

.table-input-create .arrow-buttons {
    display: flex;
    justify-content: space-between;
    margin: 0 -2px;
}

.table-input-create .arrow-buttons > * {
    margin: 0 2px !important;
}

.table-input-create .arrow-buttons-col {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    margin: -2px 0;
}

.table-input-create .arrow-buttons-col > * {
    margin: 2px 0 !important;
}

.table-input-create .table-wrapper .row-cell {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.table-input-create .table-wrapper .row-cell,
.table-input-create .table-wrapper th > div {
    height: 100% !important;
}

.table-input-create .table-wrapper .row-cell .ui.button {
    margin-right: 6px !important;
}

.table-input-create .table-wrapper .ui.button.mini {
    padding: 0.4em !important;
}

.table-input-create .table-wrapper .table-header-text {
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 4px 0;
}

.table-input-create .table-wrapper .table-header-text > span {
    max-height: 120px;
    width: max-content;
    overflow: auto;
    padding: 2px;
    margin: 0 4px;
}

.table-input-create .table-wrapper .table-row-initial span {
    max-height: 120px;
    max-width: 200px;
    overflow: auto;
    padding: 2px;
    margin: 0 4px;
}

.table-input-create .table-wrapper .ui.table thead th {
    min-width: 100px;
    max-width: 250px;
}

.table-input-create .table-wrapper .main-header-cell {
    display: flex;
    align-items: center;
}

.table-input-create .table-wrapper .main-header-cell > span {
    width: fit-content;
    white-space: pre;
}

.table-input-create .table-wrapper .main-header-cell > :first-child {
    margin-right: 12px;
}

.table-input-create .table-wrapper .highlighted {
    background-color: aliceblue;
}

.table-input-display .table-wrapper {
    max-height: 2000px;
    max-width: 100%;
    overflow: auto;
}

.table-input-display .input-cell {
    padding: 0 !important;
}

.table-input-display .input-cell.validation-error,
.table-input-display .input-cell.validation-error textarea,
.table-input-display .input-cell.validation-error input {
    background: #fff6f6 !important;
}

.table-input-display .input-cell .cell-content-container textarea,
.table-input-display .input-cell .cell-content-container .ui.input > input {
    border: none !important;
    resize: none;
    max-width: unset !important;
    box-sizing: content-box;
    max-height: 200px;
    overflow: auto;
    width: 100%;
    padding: 0.25em !important;
    box-sizing: border-box;
}

.table-input-create span.color-picker-activator {
    display: flex;
    align-items: center;
    white-space: pre;
    padding: 10px;
    border-radius: 6px;
    border: 1px solid lightgray;
}

.table-input-create span.color-picker-activator .color-preview {
    width: 16px;
    height: 16px;
    border-radius: 4px;
    margin: 0 6px;
    border: 1px solid lightgray;
}

.table-input-create .group-legend,
.table-input-display .group-legend {
    margin: 1em 0;
    display: flex;
    flex-direction: column;
}

.table-input-create .group-legend .legend-element,
.table-input-display .group-legend .legend-element {
    display: inline-flex;
    align-items: center;
    margin: 0 0.5em;
    padding: 6px;
    border: 1px solid transparent;
}

.table-input-create .group-legend .legend-element.selected {
    border: 1px solid lightgray;
    border-radius: 5px;
}

.table-input-create .group-legend .legend-element .circle,
.table-input-display .group-legend .legend-element .circle {
    display: inline-flex;
    box-sizing: border-box;
    border-radius: 50%;
    width: 18px !important;
    height: 18px !important;
    min-width: 18px !important;
    min-height: 18px !important;
    margin: 0 6px;
}

.table-input-create .ui.selection.dropdown {
    min-width: 142px !important;
}

.table-input-create .group-delete-btn {
    min-height: 42px !important;
    min-width: 42px !important;
    height: 42px !important;
    width: 42px !important;
    padding: 0.5em !important;
}
.table-input-create .group-delete-btn > i {
    font-size: 16px !important;
}
.table-input-create .table-dimension-input .ui.checkbox.required-checkbox {
    min-width: 90px;
    display: flex;
    align-items: center;
}
.table-input-create .alert,
.table-input-display .alert {
    color: red !important;
}

.table-input-create .row-display-cell,
.table-input-display .row-display-cell {
    padding: 0.2em !important;
    min-width: 75px;
    max-width: 250px !important;
    height: 42px;
}

.table-input-display thead tr th,
.table-input-create thead tr th {
    padding: 0.2em 0.4em !important;
    font-size: small;
    line-height: 1.25;
}

/* Chrome, Safari, Edge, Opera */
.table-input-display input::-webkit-outer-spin-button,
.table-input-display input::-webkit-inner-spin-button,
.table-input-create input::-webkit-outer-spin-button,
.table-input-create input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
/* Firefox */
.table-input-display input[type="number"],
.table-input-display input[type="number"] {
    -moz-appearance: textfield !important;
    appearance: textfield !important;
}

.table-input-display .locked {
    margin: 2rem 0px;
    display: flex;
    flex-direction: column;
    opacity: 0.75;
    font-style: italic;
}

.table-input-display .locked table {
    margin: 0;
}

#root .table-input-display .locked input,
#root .table-input-display .locked textarea {
    color: #a0a4a4;
}
#root .table-input-display .locked td,
#root .table-input-display .locked td input,
#root .table-input-display .locked td textarea {
    background-color: rgb(247, 247, 247);
    color: rgb(96, 96, 96);
}

#root .table-input-display .locked td.special-error {
    background-color: #fff8db;
    color: #b58105;
    box-shadow: 0 0 0 1px #b58105 inset, 0 0 0 0 transparent;
    padding: 0.125rem !important;
    position: relative;
}

#root .table-input-display .locked td.special-error input,
#root .table-input-display .locked td.special-error textarea {
    background-color: #fff8db;
    color: #b58105;
}

#root .table-input-display .locked td.special-error .popup-trigger {
    position: absolute;
    inset: 0;
    z-index: 10000;
}

#root .table-input-display .locked td.special-error .popup-trigger > * {
    pointer-events: none;
}
