.home-section .form-item {
    font-size: xx-large;
    line-height: 1;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
}

.home-section .ui.card {
    width: 100%;
}

.forms-list {
    width: 100%;
}

#forms-content .form-item {
    margin: 10px;
}

#forms-content .form-item:first-child {
    margin-left: 0px;
}

#legend {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: auto !important;
    padding-left: 50px;
    min-width: 250px;
}

#legend .legend-item {
    display: -webkit-box;
    margin: 10px 0;
    font-size: x-large;
}

.ui.circular.label {
    margin: 0 16px;
}

.forms-list .ui.card.blue {
    box-shadow: inset 10px 0px 0px 0px rgb(13, 77, 161);
    padding-left: 15px;
}

.forms-list .ui.card.yellow {
    box-shadow: inset 10px 0px 0px 0px rgb(255, 213, 0);
    padding-left: 15px;
}

.form-item .ui.yellow.circular.label {
    background-color: rgb(255, 213, 0) !important;
}

.form-item .ui.blue.circular.label {
    background-color: rgb(13, 77, 161) !important;
}

.news-feed-display {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    height: 80px;
}

.news-feed-pagination-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
}

.news-feed-pagination-container i.icon {
    cursor: pointer;
}

#first-login-modal,
#first-login-modal > .actions {
    text-align: center;
}

#first-login-modal a {
    text-decoration: underline;
}
