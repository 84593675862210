.ui.modal > .download-modal-content {
    display: flex;
    flex-direction: column;
}

.ui.modal > .download-modal-content .ui.checkbox {
    margin: 4px 0;
}

.ui.modal .dropdown {
    width: 20rem;
    margin-bottom: 2rem;
}
