.non-exhaustive-tabular .tabular-view-table-container {
    overflow-x: auto;
    max-width: 100%;
}

.non-exhaustive-tabular .tabular-view-controls-container {
    margin-top: 10px;
}

.non-exhaustive-tabular .tabular-view-table .results-cell.clickable:hover {
    background-color: rgb(239, 239, 239);
}

#validation-failed-message {
    margin: 10px 0;
}

#validation-failed-message b {
    color: red;
}

#edit-save-button i {
    color: white;
}

.container-header .download-button {
    margin-right: 10px !important;
}
