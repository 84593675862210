.report-card {
    width: 100%;
    height: fit-content !important;
    margin-top: 0px !important;
    position: relative;
}

.report-card .header {
    display: -webkit-box !important;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 69px;
}

.report-card .meta {
    height: 120px;
    display: -webkit-box;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-top: 7px;
}

.report-card .description p {
    display: -webkit-box !important;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    height: 19px;
}

.report-card.admin.public {
    box-shadow: inset 10px 0px 0px 0px rgb(0 169 144) !important;
    padding-left: 15px !important;
}

.report-card.admin.internal {
    box-shadow: inset 10px 0px 0px 0px rgb(255 106 0) !important;
    padding-left: 15px !important;
}
