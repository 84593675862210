.countries-selector {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
}

.countries-selector button {
    width: 160px;
    margin-bottom: 10px !important;
}

.countries-selector .countries {
    max-height: 500px;
    columns: 2;
    margin-bottom: 15px;
}

.countries-selector .ui.checkbox {
    display: block;
    margin-bottom: 5px;
}

.countries-selector .country-with-suboptions {
    display: flex;
}

.countries-selector .accordion.ui  {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.countries-selector .accordion.ui .title {
    padding: 0 0 0 9px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    margin-top: -1px;
}