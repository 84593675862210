.front-page-data {
    display: flex;
    margin-top: 20px;
}

.front-page-data .basic-info {
    width: 30%;
}

#root .contents-button.ui.button {
    margin-top: 10px;
}

.report-container {
    min-height: calc(100vh - 160px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.navigation-container-foward {
    display: flex;
    justify-content: flex-end;
}
