.notifications-count-container {
    color: #1c3968 !important;
    position: relative;
}

.notifications-count-container .ui.circular.label {
    position: absolute;
    bottom: -5px;
    right: -15px;
    padding: 0.35em !important;
    min-width: 1.8em !important;
    min-height: 1.8em !important;
}