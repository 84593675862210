table.forms-overview td.clickable {
    cursor: pointer;
    text-decoration: underline;
    padding-left: 20px;
    padding-right: 20px;
}

table.forms-overview tr {
    text-align: center;
    vertical-align: top;
}

table.forms-overview td.new {
    box-shadow: inset 12px 0px 0px 0px rgb(13, 77, 161);
}

table.forms-overview td.closed-not-submitted {
    box-shadow: inset 12px 0px 0px 0px rgb(255, 106, 0);
}

table.forms-overview td.partially-filled {
    box-shadow: inset 12px 0px 0px 0px rgb(255, 213, 0);
}

table.forms-overview td.submitted {
    box-shadow: inset 12px 0px 0px 0px rgb(0 169 144);
}

#root table.forms-overview td .icon {
    margin: 0 4px;
}

div.ui.selection.dropdown.filter-selection {
    min-width: 100% !important;
}

#root table.forms-overview th {
    overflow: visible;
}

#root table.forms-overview td .data-delivery-button {
    margin: 0 4px;
    padding: 7px;
    max-width: 36.15px;
    max-height: 35.6px;
    vertical-align: bottom !important;
}

#root table.forms-overview td .unpublish-button-holder {
    display: inline-block;
    width: fit-content;
    height: fit-content;
}
