.login-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #eceff2;
}
.login-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    background-color: #e0e4e7;
    padding: 20px 40px;
    width: 500px;
    border-radius: 10px;
}
.login-form-container label {
    margin-left: auto;
}
#root .login-form-container input {
    background-color: #eceff2 !important;
}
.login-form-container hr {
    width: 100%;
    border-top: 1px solid #cbd1d8;
    border-bottom: none;
    box-sizing: border-box;
}
#login-error-message {
    text-align: center;
    font-weight: bold;
    height: 20px;
    margin: 10px 0 !important;
    color: #c0392b !important;
}
#login-locked-message {
    margin-top: 20px;
    text-align: center;
    color: black !important;
}
.login-options {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    font-weight: bold;
}
.login-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}
.login-wrapper > img {
    width: 350px;
}
#cursor {
    cursor: pointer;
}
#centered-text {
    text-align: center !important;
}

#mini-logo {
    display: none;
}

#logo-text {
    display: none;
}

/*responsive part */

@media only screen and (max-width: 635px) {
    .login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        height: 100vh;
        background: #fff;
    }

    .login-wrapper {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        height: 100%;
    }

    .center-login-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        height: 100%;
        max-height: 500px;
        width: 100%;
        max-width: 340px;
        margin-bottom: 10px;
    }

    .login-form-container {
        flex: 4;
        display: flex;
        flex-direction: column;
        justify-content: center;
        gap: 12px;
        background-color: #f0f1f2 !important;
        padding: 30px 20px;
        width: 340px;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        border-top-right-radius: 0 !important;
    }

    .lower-container {
        border-top-right-radius: 10px;
        border-bottom-left-radius: 0 !important;
        background: #2e4672;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    #mini-logo {
        display: block;
        width: 35%;
        fill: white;
    }

    #logo-text {
        display: block;
        width: 70%;
    }

    #logo {
        display: none;
    }

    #bmk-logo {
        display: none;
    }

    .upper-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

}
