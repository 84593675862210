.chapter-container {
    min-height: calc(100vh - 160px);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.chapter-content-container {
    display: flex;
}
.chapter-container .chapter-content-container .chapter-content {
    max-width: 1000px;
    width: 100%;
    padding: 30px 0;
    display: flex;
    flex-direction: column;
}
.chapter-container .chapter-content-container .chapter-content > .ui.button {
    width: fit-content;
}
#root .chapter-select.ui.dropdown {
    margin: 5px 0;
    width: 300px;
    z-index: 100;
}
.text-editor {
    width: 95%;
    background-color: white;
}
.text-editor > .ql-container {
    height: auto;
}
.text-editor .text-content-editor {
    max-height: 300px;
    height: fit-content;
    overflow: auto;
}
div.figure-container {
    display: flex;
    justify-content: center;
}
div.figure-container,
.rte-content-container {
    padding-bottom: 20px;
}
div.figure-container > div > img {
    object-fit: contain;
    max-width: 100%;
    max-height: 300px;
}
.chapter-content div.ui.vertical.menu.add-menu .add-dropdown {
    background: #1c3968;
    color: white !important;
    width: 90px;
    border-radius: 0.28571429rem;
}
.chapter-content div.ui.vertical.menu.add-menu.menu {
    width: 90px;
}
.chapter-content div.ui.vertical.menu.add-menu:hover .add-dropdown:hover {
    background-color: #435b81;
}
.chapter-content div.ui.vertical.menu.add-menu .add-dropdown > .text {
    color: white !important;
    font-weight: bold;
}
.chapter-content label {
    flex: 1;
    font-weight: bold;
    padding: 0px;
    margin-bottom: 0px;
}
div.figure-container > .figure-content-container {
    display: flex;
    flex-direction: column;
}
div.figure-container > .figure-content-container > p {
    text-align: center;
    margin: 6px 0;
}
