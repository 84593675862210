.users-dashboard-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: 40px;
    margin-top: 20px;
}
.users-dashboard-container > .users-table-container {
    flex: 1.5;
}
.users-dashboard-container > .add-user-form-container {
    flex: 1;
}
.add-user-form {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 20px;
}
.add-user-form-container button,
.add-user-form-container .segment > .input-container {
    margin-top: 20px !important;
}
div.content.add-user-toolpit-content ul.tooltip-list {
    padding-left: 10% !important;
    margin: 0px !important;
}
.users-dashboard-container > .users-table-container .filter-container {
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin: 20px 0;
}

.users-dashboard-container > .users-table-container .filter-container .ui.selection.dropdown,
.users-dashboard-container > .users-table-container .filter-container .ui.input {
    max-width: 170px !important;
    min-width: 100px !important;
    width: 160px !important;
}

.users-dashboard-container > .users-table-container .filter-container > div:not(:last-child) {
    display: flex;
    align-items: center;
    margin-right: 12px;
}

.users-dashboard-container > .users-table-container .filter-container label {
    margin-right: 4px;
}

.users-dashboard-container > .users-table-container table .dropdown-cell {
    min-width: 14em;
}

.users-dashboard-container > .users-table-container table .centered {
    display: flex;
    align-items: center;
    margin: 2px 0;
}
