.meta {
    height: 150px;
    overflow: hidden;
}

.section-title h1 {
    border-bottom: 2px solid #666868;
}

.home-section .ui.card {
    width: 100%;
}

.action-bar-container {
    width: 100%;
}