.frt-chart-container {
    padding-top: 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.frt-chart-container .chart-type-selector {
    display: flex;
    flex-direction: column;
    margin: 0 16px;
}

.frt-chart-container .chart-type-selector button {
    min-width: 200px;
    margin-bottom: 15px !important;
}

.frt-chart-container .countries-selector {
    margin-left: auto;
    padding-right: 30px;
}

.frt-chart-container .additional-chart-wrapper {
    padding-left: 20px;
    padding-right: 40px;
    width: 100%;
    overflow: visible;
}

.frt-chart-container .frt-additional-tooltip {
    max-width: 200px;
    background-color: white;
    padding: 12px;
}

.frt-chart-container .additional-chart-wrapper .recharts-legend-wrapper {
    font-size: 12px;
    margin-top: 10px;
}
