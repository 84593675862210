.clone-options .content form {
    display: flex;
}

.clone-options .content form .field:first-child {
    min-width: 300px;
    margin: 0;
    margin-right: 20px;
}

.clone-options .content form .checkbox {
    padding-top: 10px;
}

.create-form-modal {
    max-width: 500px;
}

.from-published-modal {
    max-width: 450px;
}