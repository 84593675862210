.parent-tooltip {
    position: relative;
    width: fit-content;
    margin-left: 15px;
}

.child-tooltip {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.create-new-form-modal div.actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.special-deadline-container {
    display: flex;
    align-items: flex-start;
    margin: 0 -10px !important;
}
.field.action-bar-container {
    display: initial !important;
}
.special-deadline-container > div,
.special-deadline-container > .field,
.special-deadline-container > .ui.button {
    margin: 0 10px;
}
.ui.form .field .special-deadline-container > .selection.dropdown {
    width: 220px;
}
.special-deadline-container .action-bar-container-options i.icon {
    font-size: 1.35em;
}
.ui.button.special-deadline-btn {
    margin-left: 10px;
}

.user-download-toggle {
    margin-bottom: 10px !important;
}

.notifications-container {
    display: flex;
    flex-flow: row wrap;
    margin-top: 10px;
    opacity: 0;
    transition: ease-in-out all 0.5s;
    height: 0;
    visibility: hidden;
}

.notifications-visible {
    opacity: 1;
    height: 103px;
    visibility: visible;
}

.notification-toggle {
    display: flex !important;
    justify-content: space-between;
    width: 300px;
    margin-top: 10px;
}

@media only screen and (max-width: 1540px) {
    .notification-toggle {
        display: flex !important;
        justify-content: space-between;
        width: 280px;
        margin-top: 10px;
    }
}
