.form-view-container {
    width: 100vw;
    min-height: 100vh;
    display: flex;
    align-items: center;
    flex-direction: column;
    position: relative;
}

.form-view-container > div {
    width: 940px;
    padding: 20px;
}

.form-view-container .form-header {
    padding: 10px 20px;
}

.form-view-container .form-view {
    display: flex;
    flex-direction: column;
    background-color: white;
    flex-grow: 1;
}

.form-view-container .form-view .buttons-container {
    display: flex;
    margin-top: auto;
    justify-content: flex-end;
}

.form-view-container .form-view .buttons-container button {
    margin-left: 15px !important;
}

.form-view-container .form-header {
    background-color: #0f218b;
    color: white;
}

.form-view-container .form-header img {
    height: 40px;
    width: auto;
}

.form-view-container .form-header h3 {
    margin-top: 0px;
    margin-left: 5px;
    color: white;
    font-weight: normal !important;
}

.form-view-container .form-view section {
    display: none;
}

.form-view-container .form-view section.active {
    display: block;
}

.form-view-container #section-navigation {
    position: fixed;
    right: 30px;
    top: 10px;
}

.form-view-container #section-navigation .item.error {
    color: red;
}

.form-view-container .legend {
    display: flex;
    align-items: baseline;
}

.form-view-container .legend .latest-data {
    margin: 0 15px;
}

#root .form-view-container .legend .latest-data .ui.button {
    margin: 0 5px;
}

.form-view-container .legend span {
    font-size: larger;
    margin-left: 5px;
}

.form-view-container .legend .remark span {
    color: orange;
}

.form-view-container .legend .required span {
    color: red;
}

.file-avatars-conatiner {
    columns: 2;
}

.file-avatars-conatiner .file-avatar > div:first-child {
    flex-grow: 1;
    display: flex;
    align-items: flex-start;
    padding: 6px;
    margin-bottom: 5px;
    border-radius: 5px;
    min-width: 0px;
    max-width: calc(100% - 100px);
}

.file-avatars-conatiner .file-avatar > div:first-child i {
    min-width: 33px;
}

.file-avatars-conatiner .file-avatar > div:first-child span {
    margin-top: 4px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
}

.file-avatars-conatiner .file-avatar:hover > div:first-child {
    background-color: #e0e1e2;
}

#form-info-container.ui.segment.form-info {
    position: fixed;
    left: 10px;
    top: 10px;
    width: 250px;
    border-radius: 0.28571429rem !important;
    padding: 20px 25px !important;
}

.special-error-validation {
    font-size: 16px;
    margin-right: 2rem;
}

.special-error-validation strong {
    display: inline-block;
    width: 200px;
}

@media screen and (max-width: 900px) {
    .file-avatars-conatiner {
        columns: 1;
    }
}
