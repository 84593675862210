.ui.tabular.menu .active.item {
    background-color: transparent;
    border-width: 2px;
    border-top: none;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
}

.ui.tabular.menu .item {
    justify-content: center;
}

.ui.segment.active.tab {
    background-color: transparent;
    border: none;
    box-shadow: none;
}

.ui.vertical.tabular.menu {
    border: none;
}

.input-data-editor-container .ui.grid .vertical.menu .item {
    width: 150px;
    height: 42px;
    font-size: small;
    padding: 10px;
    background-color: #1c3968;
    border-color: #1c3968;
}

.input-data-editor-container .ui.grid .vertical.menu .active.item {
    width: 150px;
    height: 42px;
    font-size: small;
    padding: 10px;
    border-color: #1c3968;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 10px 10px;
}

.vertical-button {
    margin-bottom: 20px !important;
}

.horizontal-button {
    margin-right: 20px !important;
}

.first-level-tabs .ui.tabular.menu {
    border: none;
}

.first-level-tabs .ui.tabular.menu > a {
    margin-top: 6px;
    width: 50%;
    height: 40px;
    justify-content: center;
    border: none;
}

.second-level-tabs .ui.tabular.menu {
    border: 6px !important;
}

.second-level-tabs .ui.tabular.menu > a {
    width: 25%;
    justify-content: center;
    height: 40px;
    border-bottom: 2px !important;
}

.third-level-tabs.hidden .ui.tabular.menu {
    display: none;
}

.third-level-tabs .ui.tabular.menu > a {
    width: 20%;
    justify-content: center;
    height: 40px;
    border-bottom: 2px !important;
}

#root .first-level-tabs .ui.segment {
    padding: 0 !important;
}

.graph-container {
    display: flex;
    flex-direction: row;
}

.vertical-buttons {
    display: flex;
    flex-direction: column;
    margin-top: 50px;
    margin-right: 50px;
    flex-shrink: 0;
}

.graph-display-container {
    position: relative;
    display: flex;
    flex-direction: row;
    flex-grow: 1;
    align-items: stretch;
}

.graph-display-container .countries-selector {
    margin-left: auto;
}

.graph-display-container .graph-display {
    min-width: 400px;
    max-width: 1200px;
    max-height: 500px;
    flex-grow: 1;
    padding-right: 20px;
}

.third-level-tabs .item.menu-popup-container {
    padding: 0 !important;
}

.menu-popup-trigger {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}
