.figures-container {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 25px;
    width: 100%;
    margin: 20px 0;
}
.figures-container p {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    text-align: center;
}
.figures-container img {
    width: 100%;
    min-height: 120px;
    max-height: 230px;
    object-fit: contain;
    cursor: pointer;
}
.selection-modal-pagination-container {
    text-align: center;
    padding-top: 20px;
}
.image-content-container {
    overflow-y: auto;
    display: flex;
    flex-direction: column;
}
.image-content-container img.figure {
    max-width: 100%;
    object-fit: contain;
    margin: 10px 0;
}
