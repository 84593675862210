.map-chart {
    width: 48vw;
    pointer-events: auto;
}
.code-compliance-overview-content {
    display: flex;
    flex-direction: column;
}
.code-compliance-overview-content-map .legend-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}
.code-compliance-overview-content-map,
.code-compliance-overview-content-map .map-legend p {
    display: flex;
}
.code-compliance-overview-content-map .map-legend {
    margin-top: 1vw;
    margin-left: 2vw;
}
#root .code-compliance-overview-content-map .legend-container .ui.button {
    margin-left: 2vw;
}
.code-compliance-overview-content-map .map-legend p {
    align-items: center;
}
.code-compliance-overview-content-map .map-legend span {
    height: 25px;
    width: 25px;
    border-radius: 50%;
    margin-right: 10px;
}
.code-compliance-overview-content-map .map-legend span.new {
    background-color: #0d4da1;
}
.code-compliance-overview-content-map .map-legend span.red {
    background-color: #ce202c;
}
.code-compliance-overview-content-map .map-legend span.partial {
    background-color: #ffd500;
}
.code-compliance-overview-content-map .map-legend span.submitted {
    background-color: #00a990;
}
.code-compliance-overview-content-map .map-legend .gradient {
    position: relative;
}
.code-compliance-overview-content-map .map-legend .gradient img {
    width: 25px;
    height: auto;
}
.code-compliance-overview-content-map .map-legend .gradient span {
    position: absolute;
    left: 35px;
}
.code-compliance-overview-content-map .map-legend .gradient span:first-of-type {
    top: 0px;
}
.code-compliance-overview-content-map .map-legend .gradient span:last-of-type {
    bottom: 0px;
}
.react-multi-carousel-list .ui.card.active {
    background-color: lightgray !important;
}
.code-compliance-content-container {
    display: flex;
    justify-content: space-around;
    padding: 20px 0;
}
.sections-selection-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
#root .sections-selection-container .ui.button {
    margin: 10px 0;
    width: 300px;
}
