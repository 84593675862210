.home-container .home-header {
    text-align: center;
    position: relative;
    height: 420px;
}

.home-container .home-header img {
    width: 100vw;
    height: 450px;
    object-fit: cover;
    position: relative;
    max-width: unset;
    left: -5vw;
    top: -30px;
}

.home-container .home-section {
    margin: 10px 0;
}

.home-container .home-section .section-title h1 {
    border-bottom: 2px solid #666868;
}

#news-feed .feed-item {
    margin: 6px 0;
}

.home-header h1 {
    color: white;
    position: absolute;
    transform: translate(-50%, 50%);
    bottom: 50%;
    left: 50%;
    margin: 0px;
    max-width: 600px;
}
