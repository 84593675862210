.generation-capacity-pie .recharts-default-tooltip p {
    margin-bottom: 0;
}

.generation-capacity-pie .recharts-surface {
    overflow: visible !important;
}

@media screen and (max-width: 1250px) {
    .recharts-default-legend {
        display: none;
    }
}
