.question-create .header-container {
    display: flex;
    align-items: flex-start;
}

.question-create .header-container .field .toggle {
    float: left;
}

.question-create .header-container .field:first-child {
    flex-grow: 1;
    margin-right: 20px;
}

.question-create .header-container .field:nth-child(2) {
    border-right: 1px solid #d4d4d5;
    padding-right: 10px;
}

.question-create .header-container .field.linked {
    border-left: 1px solid #d4d4d5;
    padding-left: 10px;
}

.question-create .header-container .field:not(:first-child) {
    margin-left: 10px;
}

.question-create .header-container .checkbox {
    margin-top: 7px;
}

.question-create .boundaries-container {
    display: flex;
}

.question-create .boundaries-container .field {
    flex-grow: 1;
}

.question-create .boundaries-container .field:first-child {
    margin-right: 20px;
}

.question-create .branching-toggle {
    display: flex;
    justify-content: space-between;
}

.question-create .branching-options {
    display: flex;
    align-items: center;
}

.question-create .branching-options p {
    margin: 0 10px !important;
}

.question-create .branching-options div:last-child {
    flex-grow: 1;
}

.question-create .question-text-label {
    display: flex !important;
    justify-content: space-between;
    align-items: center;
}

.question-create .question-image-container,
.image-display-container,
#modal-img-container {
    display: flex !important;
    justify-content: center;
    margin: 8px 0;
}

.question-create .question-image-container img,
.image-display-container img,
#modal-img-container img {
    max-width: 100%;
    max-height: 500px;
    object-fit: contain;
}

.remove-image-btn-container {
    display: flex;
    justify-content: center;
}
