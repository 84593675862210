.country-select-container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background: #eceff2;
}

.country-select-form-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 20px;
    background-color: #e0e4e7;
    padding: 20px 40px;
    width: 500px;
    border-radius: 10px;
}

.country-select-form-container label {
    margin-left: auto;
}

#root .country-select-form-container input {
    background-color: #eceff2 !important;
}

.country-select-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.country-select-wrapper > img {
    width: 350px;
}

.country-select-wrapper h1 {
    text-align: center !important;
}