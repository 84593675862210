.question-wrapper {
    width: 830px !important;
    margin-bottom: 20px !important;
}

.question-wrapper-container {
    margin-bottom: 40px;
    position: relative;
}

.question-wrapper-container.editor.section {
    width: 935px !important;
    margin-bottom: 15px;
}

.question-wrapper-container.editor {
    width: 980px !important;
    padding-bottom: 15px;
    margin-bottom: 0px;
}

.question-wrapper-container .option-input {
    margin-bottom: 5px;
}

.question-wrapper-container .question-options {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}
