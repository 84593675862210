.input-data-editor-container .ui.grid {
    margin-top: 10px;
}
.input-data-editor-container .ui.grid .vertical.menu .item {
    background-color: #666868;
    text-align: center;
    font-weight: bold;
    color: white;
    font-size: 18px;
    margin-bottom: 15px;
    padding: 15px;
    line-height: 20px;
    transition: all 0.1s ease-in-out;
    border-radius: 8px !important;
}
.input-data-editor-container .ui.grid .vertical.menu .active.item {
    background-color: #1c3968;
    transition: all 0.1s ease-in-out;
}
#root .input-data-editor-container .ui.grid .segment {
    background-color: transparent;
    border-radius: 0 !important;
    border: none;
    padding: 0 !important;
}
.input-data-editor-container .ui.grid .segment .accordion .title,
.input-data-editor-container .ui.grid .segment .accordion .content {
    background-color: #eceff2;
}
.input-data-editor-container .ui.grid .segment .accordion .title:not(:first-child) {
    margin-top: 15px;
}
.input-data-editor-container .ui.grid .segment .accordion .title {
    font-size: 18px;
    font-weight: bold;
    padding: 15px;
    line-height: 20px;
    position: relative;
    text-align: center;
    border-radius: 8px;
}
.input-data-editor-container .ui.grid .segment .accordion .title > i {
    position: absolute;
    right: 10px;
    transform: rotate(0deg);
    transition: transform 0.1s linear;
}
.input-data-editor-container .ui.grid .segment .accordion .active.title {
    border-radius: 8px 8px 0 0;
}
.input-data-editor-container .ui.grid .segment .accordion .active.title > i {
    transform: rotate(-90deg);
    transition: transform 0.1s linear;
}
.input-data-editor-container .ui.grid .segment .accordion .content {
    border-radius: 0 0 8px 8px;
    padding: 20px 50px !important;
}

.form-item-container .ui.card{
    width: 100%;
}
