.external-publications-container .title-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.external-publications-container .section-container {
    margin: 16px 0;
}

.external-publications-container .section-container .publication-section-row {
    display: flex;
    align-items: center;
    margin: 6px 0;
}

.external-publications-container .section-container .publication-section-row > * {
    margin-right: 10px;
}
