.admin-figures-filter {
    width: 85%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
    margin-top: 20px;
}

.admin-figures-filter > span {
    height: fit-content;
}

.filter-section {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.filter-section.year {
    width: 80px;
}

.filter-section.source {
    width: 120px;
}

.filter-section.category {
    width: 280px;
}

.filter-section.form {
    width: 320px;
}

.filter-section p {
    margin-bottom: 5px;
}

.admin-figures-content {
    margin-top: 20px;
}

.country-button {
    margin-left: 15px !important;
}

.filter-button {
    height: fit-content !important;
    margin-top: 24px !important;
}

.figure-row {
    display: flex;
    flex-direction: row;
    padding: 5px 0;
    cursor: pointer;
}

.figure-row-container {
    max-width: 900px;
    flex-grow: 2;
}
.img-grid {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    gap: 25px;
    width: 100%;
}
.large-icons > .action-bar-container-options {
    display: flex;
    flex-direction: column;
}
.large-icons > .action-bar-container-options > i {
    margin: 2px;
    font-size: 1.3em;
}
.large-icons > .action-bar-container-options > i:first-child {
    margin-top: 0px;
}
.large-icons > div.img-contain {
    max-width: 90%;
}
.large-icons > div.img-contain > p,
.list-view > div.img-contain > p {
    display: -webkit-box;
    max-width: 100%;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
}
.large-icons > div.img-contain > img,
.list-view > div.img-contain > img {
    width: 100%;
    min-height: 120px;
    max-height: 230px;
    object-fit: contain;
    cursor: pointer;
}
.figure-row:hover {
    background-color: lightgrey;
}

.figure-row h3 {
    margin-top: 0 !important;
}

.figures-pagination-container {
    text-align: center;
    padding-top: 20px;
}

@media only screen and (max-width: 1725px) {
    .large-icons > .action-bar-container-options > i {
        margin: 2px;
        font-size: 1.1em;
    }

    .filter-section.year {
        width: 75px;
    }

    .filter-section.source {
        width: 100px;
    }

    .filter-section.category {
        width: 260px;
    }

    .filter-section.form {
        width: 320px;
    }
}
.image-content-container {
    display: flex !important;
    justify-content: center;
}
.image-content-container img.figure {
    max-width: 100%;
    object-fit: contain;
}
#view-select {
    margin: 16px 0;
}
#view-select > button:first-child {
    margin-right: 10px !important;
}
.list-view .figure-row i {
    font-size: 1.2em;
    margin-right: 10px;
}

.list-view .delete-select-checkbox {
    margin-right: 10px;
    margin-top: 6px;
}

.delete-selection-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 20px;
}

.delete-selection-row > * {
    margin-right: 20px;
}

.regenerate-modal .image-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.regenerate-modal .image-wrapper img {
    width: 100%;
    width: 500px;
    max-height: 500px;
    object-fit: contain;
}

.regenerate-modal .form .regenerate-labels-wrapper {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 5px;
    padding: 6px;
    margin: 20px 0;
}

.regenerate-modal .form .regenerate-input-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.regenerate-modal .form .regenerate-input-wrapper > * {
    margin: 0 8px;
}

.regenerate-modal > .actions > *,
.generate-modal > .actions > * {
    margin: 0 8px !important;
}
