.empty-reports-message {
    margin: 15px 0;
}

.legend-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    margin: 6px 0 15px 0;
    font-size: medium;
}

.legend-container .legend-item {
    display: flex;
    flex-direction: row;
    align-items: center;
}
