.question-wrapper-container.display {
    flex-direction: column !important;
}

.question-wrapper-container .title span.star {
    font-size: larger;
    margin-left: 5px;
}

.question-wrapper-container .title span.number {
    margin-right: 5px;
}

.question-wrapper-container .remark span.star {
    color: orange;
}

.question-wrapper-container .required span.star {
    color: red;
}

.question-wrapper-container .title {
    display: flex;
    white-space: pre-line;
}

.question-wrapper-container .title span.attach {
    margin-left: auto;
    cursor: pointer;
}

.editor .question-wrapper-container .title span.attach {
    cursor: grab;
    pointer-events: none;
}

.question-wrapper-container .limitations {
    text-align: end;
    font-size: smaller;
}

.question-wrapper-container .ui.disabled.input {
    opacity: 1 !important;
}

.ui.form .question-wrapper-container .field :disabled {
    opacity: 0.65 !important;
}

.other-option-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
}

.other-option-container div.ui.input {
    width: 300px !important;
    margin-left: 15px !important;
}

.question-wrapper-container .checkbox.no-click {
    pointer-events: none;
}

.editor .remark-content .title {
    cursor: unset;
}

.question-wrapper-container .remark-content .title {
    padding-bottom: 0 !important;
}

.question-wrapper-container .remark-content:nth-child(3) {
    position: relative;
    top: -10px;
}

.question-wrapper-container .remark-content:nth-child(3) .title {
    padding-top: 0 !important;
}

.question-wrapper-container .remark-content textarea {
    color: #666868 !important;
}
