.publish-container {
    display: flex;
    justify-content: space-between;
    flex-grow: 1;
    margin-bottom: 20px;
}

#root .publish-container > .ui.button {
    margin: 0 10px;
}
