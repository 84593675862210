.table-of-contents {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    min-height: calc(100vh - 160px);
}

.sections-container {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    width: 100%;
    margin: 20px 0;
}

.tree-container {
    padding-bottom: 40px;
    width: 100%;
    height: 55vh;
    overflow: auto;
    margin: 10px 0;
}

.chapters-container {
    padding: 10px 7px;
    background-color: white;
    margin-bottom: 10px;
    border-radius: 5px;
    width: calc(100% - 140px);
}

.sections-content-container {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
    width: 100%;
    max-width: 640px;
    position: relative;
    font-size: 20px;
}

.sections-content-container a {
    color: #1c3968;
}

.buttons-modal {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
}

.delete-chapter-modal {
    max-width: 450px;
}

.sections-actions-container {
    position: absolute;
    top: 0;
    right: 0;
}

.sections-actions-container svg {
    height: 37;
    width: auto;
}

.sections-add-new {
    width: 100%;
    padding: 0 20px;
    text-align: center;
    cursor: pointer;
    max-width: 175px;
}

.sections-content-container .sections-actions-container {
    position: absolute;
    right: 0;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.sections-content-container .sections-actions-container i {
    background-color: white;
    border-radius: 5px;
    border-color: rgba(0, 0, 0, 0.25) !important;
    border: 1px solid;
    box-shadow: none !important;
    margin-bottom: 2px;
    cursor: pointer;
    opacity: 0;
    transition: opacity 500ms linear, background-color 500ms linear, color 500ms linear;
}

.sections-content-container:hover .sections-actions-container i {
    opacity: 1;
}

.sections-content-container .sections-actions-container i:hover {
    background-color: #1c3968;
    color: white;
    border-color: transparent !important;
}

.table-of-contents .navigation-container {
    margin-top: auto;
    width: 100%;
    display: flex;
    justify-content: space-between;
}

span.popup-btn-container {
    margin-left: 15px;
}

@media only screen and (max-height: 800px) {
    .tree-container {
        height: 45vh;
    }
}
