.data-view-container .year-filter {
    min-width: 100px;
}

.data-view-container .hide {
    display: none;
}

.data-view-container .show {
    display: initial;
}
