.meta {
    height: 150px;
    overflow: hidden;
}

.section-title h1 {
    border-bottom: 2px solid #666868;
}

.ui.card {
    width: 100%;
}

#forms-content {
    display: flex;
    flex-direction: column;
}